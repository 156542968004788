<template>
  <div>
    <Page
      :merchant="merchant"
      :contract-specifications="externalContractSpecifications"
      :has-external-contract-specifications="!!externalContractSpecifications.length"
      @click-new-button="(data) => endorsementCaller(data)"
      @click-draft-button="(data) => endorsementCaller(data, true)"
    />
  </div>
</template>

<script>
import EndorsementService from '@/services/osiris/EndorsementService';
import EndrosementDraftService from '@/services/osiris/EndrosementDraftService';

import TagMapper from '../../mappers/TAG';
import CercMapper from '../../mappers/CERC';

import CercEndorsementService from '@/services/osiris/Cerc/EndorsementService';
import CercEndorsmentDraftService from '@/services/osiris/Cerc/EndorsementDraftService';

import Page from './Page.vue';

const mappers = {
    TAG: TagMapper,
    CERC: CercMapper,
};

const services = {
    TAG: {
        default: EndorsementService,
        draft: EndrosementDraftService,
    },

    CERC: {
        default: CercEndorsementService,
        draft: CercEndorsmentDraftService,
    },
};

export default {
    components: {
        Page,
    },

    data() {
        return {
            merchant: this.getStoreMerchant(),
            externalContractSpecifications: this.$route.query.ContractSpecificationByConsult ? this.$route.query.ContractSpecificationByConsult.map(item => ({ ...item, is_possible_edit: true })) : [],
        };
    },

    methods: {
        async endorsementCaller(form, isDraft) {
            this.isLoading(true);
            const dataFormatedByRegister = mappers[form.register].map(form);
            dataFormatedByRegister.asset_holder = this.merchant.asset_holder || this.merchant.tax_id;
            dataFormatedByRegister.merchant_id = this.merchant.id;

            let response;

            if (isDraft) {
                response = await this.createDraftEndorsement(dataFormatedByRegister);
            } else {
                response = await this.createEndorsement(dataFormatedByRegister);
            }

            const { status, data } = response;

            if (status === 200) {
                this.modalSuccess(isDraft ? 'Rascunho da averbação criado com sucesso' : 'Averbação criada com sucesso');

                this.$router.push(`/plataforma-credito/estabelecimento/detalhes/${this.merchant.id}`);
            } else if (status === 403) {
                const confirmed = await this.confirmAnAction('Não foi possível criar sua averbação pois está fora do horário de funcionamento. \n Você gostaria de criar um rascunho dela?');
                if (!confirmed) { return; }

                await this.createDraftEndorsement(dataFormatedByRegister);
            } else if (status === 406 && data.message === 'Opt In is inactive') {
                this.modalError('Opt-in está inativo');
            } else if (status === 406 && data.message === 'Specifications total value must be equal or less to contract value') {
                this.modalError('Valor das especificações de contrato juntas deve ser menor que o saldo devido');
            } else if (status === 502) {
                this.modalError(`<p>Averbação Negada</p> <p>
                    ${form.register === 'CERC' ? data.message : ''}
                    </p>
                    `, true);
            } else {
                this.modalError('Erro ao criar averbação');
            }

            this.isLoading(false);
        },

        async createEndorsement(input) {
            const { register } = input;

            const { status, data } = await services[register].default.create(input);

            return {
                status, data,
            };
        },

        async createDraftEndorsement(input) {
            const { register } = input;

            const { status, data } = await services[register].draft.create(input);

            return {
                status, data,
            };
        },
    },
};
</script>
