<template>
  <CreditPlatformItemProductLayout
    :optin-selected-is-required="false"
    :breadcrumb-items="[
      {
        text: 'Inicio',
        href: '/plataforma-credito',

      },
      {
        text: 'Detalhes Estabelecimento',
        href: `/plataforma-credito/estabelecimento/detalhes/${merchant.id}`,
      },
      {
        text: 'Averbação',
      },
    ]"
  >
    <b-card>

      <validation-observer
        ref="averbationForm"
        #default="{ invalid }"
      >

        <form-averbation
          :register-options="registerOptions"
          :endorsement="endorsement"
          :has-external-contract-specifications="hasExternalContractSpecifications"
        />

        <contract-specifications
          :is-percentage="endorsement.division_method === 'percentage'"
          :contract-specifications="endorsement.contract_specifications"
          :fields="contractSpecificationFields"
          @import="handleImportedCsv"
          @reset="endorsement.contract_specifications = []"
        />

        <b-row class="justify-content-center">

          <b-button
            variant="primary"
            :disabled="invalid || !endorsement.contract_specifications.length"
            @click="validateAverbation()"
          >

            Salvar Averbação

          </b-button>

          <b-button
            variant="primary"
            class="ml-1"
            :disabled="invalid || !endorsement.contract_specifications.length"
            @click="validateAverbation(true)"
          >
            Salvar Averbação como Rascunho
          </b-button>

        </b-row>

      </validation-observer>

    </b-card>

  </CreditPlatformItemProductLayout>
</template>

<script>
import {
    BCard,
    BButton,
    BRow,
} from 'bootstrap-vue';

import {
    ValidationObserver,
} from 'vee-validate';
import FormAverbation from '../../Components/Form.vue';
import ContractSpecifications from '../../Components/ContractSpecifications/ContractSpecifications.vue';

import RegisterValidator from '@/register/validate-register';

import CreditPlatformItemProductLayout from '@/views/layouts/CreditPlatformItemProductLayout.vue';
import RegisterGetters from '@/register/get-registers';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? +value.replace(/[R$ .]/g, '').replace(',', '.')
    : value);

const removeSymbolsTaxId = taxId => taxId.replace(/[^a-zA-Z0-9]/g, '');

export default {
    components: {
        BButton,
        BCard,
        CreditPlatformItemProductLayout,
        FormAverbation,
        ContractSpecifications,
        BRow,
        ValidationObserver,
    },

    props: {
        merchant: {
            type: Object,
            required: true,
        },

        contractSpecifications: {
            type: Array,
            default: () => [],
        },

        hasExternalContractSpecifications: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            endorsement: {
                register: null,
                effect_type: 1,
                division_method: 'fixed',
                contract_value: 0,
                contract_specifications: [{
                    expected_settlement_date: '',
                    receivable_debtor: '',
                    payment_scheme: '',
                    effect_value: 'R$ 1,00',
                    is_possible_edit: true,
                    is_editable: true,
                }],
                bank_account: {
                    account_type: 'CC',
                },
            },
        };
    },

    computed: {
        contractSpecificationFields() {
            const noContainsInDivisionMethodPercentage = ['effect_value'];

            const fields = [
                { key: 'expected_settlement_date', label: 'previsão da liquidação' },
                { key: 'receivable_debtor', label: 'cnpj adquirente' },
                { key: 'payment_scheme', label: 'esquema' },
                { key: 'effect_value', label: 'Valor Efetivo' },
                { key: 'actions', label: 'Ações' },
            ];

            if (this.endorsement.division_method === 'percentage') {
                return fields.filter(item => !noContainsInDivisionMethodPercentage.find(key => key === item.key));
            }

            return fields;
        },

        registerOptions() {
            const options = RegisterGetters.get();

            options.unshift({
                value: null,
                text: 'Selecione uma registradora',
            });

            return options;
        },
    },

    created() {
        if (this.contractSpecifications.length) {
            this.endorsement.contract_specifications = this.contractSpecifications;
        }
    },

    methods: {
        async validateAverbation(isDraft) {
            const isConfirmedAction = await this.confirmAnAction('Deseja realmente criar essa averbação');
            if (!isConfirmedAction) return;

            this.$refs.averbationForm.validate().then(success => {
                if (success && isDraft) {
                    this.$emit('click-draft-button', this.getForm());
                    return;
                }

                if (success) {
                    this.$emit('click-new-button', this.getForm());
                }
            });
        },

        isEndorsementPercentageDivisionMethod() {
            return this.endorsement.division_method === 'percentage';
        },

        getForm() {
            const { endorsement } = this;

            const isPercentage = this.isEndorsementPercentageDivisionMethod();

            return {
                ...endorsement,
                percentage: isPercentage ? +endorsement.percentage : undefined,
                contract_value: removeSymbolsMoney(endorsement.contract_value),
                beneficiary: removeSymbolsTaxId(endorsement.beneficiary),

                bank_account: {
                    ...endorsement.bank_account,
                    bank: endorsement.bank_account.bank.bank,
                    document_number: removeSymbolsTaxId(endorsement.bank_account.document_number),
                },

                contract_specifications: endorsement.contract_specifications.map(item => ({
                    ...item,
                    effect_value: isPercentage ? undefined : removeSymbolsMoney(item.effect_value),
                    is_new: true,
                    original_asset_holder: this.merchant.asset_holder,
                    receivable_debtor: removeSymbolsTaxId(item.receivable_debtor),
                })),
            };
        },

        handleImportedCsv(event) {
            this.endorsement.contract_specifications = event;
        },
    },
};
</script>
