import BaseOsirisService from '../BaseOsirisService';

const BASE_MERCHANT = 'cerc/draft/endorsement';

class EndorsementDraftService extends BaseOsirisService {
    async create(data) {
        try {
            return await this.api.post(BASE_MERCHANT, data);
        } catch ({ response }) {
            return response;
        }
    }

    async index() {
        try {
            return await this.api.get(BASE_MERCHANT);
        } catch ({ response }) {
            return response;
        }
    }

    async show(id) {
        try {
            return await this.api.get(`${BASE_MERCHANT}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async update({ id, data }) {
        try {
            return await this.api.put(`${BASE_MERCHANT}/${id}`, data);
        } catch ({ response }) {
            return response;
        }
    }

    async confirm({ id }) {
        try {
            return await this.api.post(`${BASE_MERCHANT}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async delete({ id }) {
        try {
            return await this.api.delete(`${BASE_MERCHANT}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new EndorsementDraftService();
