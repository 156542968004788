var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CreditPlatformItemProductLayout',{attrs:{"optin-selected-is-required":false,"breadcrumb-items":[
    {
      text: 'Inicio',
      href: '/plataforma-credito',

    },
    {
      text: 'Detalhes Estabelecimento',
      href: ("/plataforma-credito/estabelecimento/detalhes/" + (_vm.merchant.id)),
    },
    {
      text: 'Averbação',
    } ]}},[_c('b-card',[_c('validation-observer',{ref:"averbationForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var invalid = ref.invalid;
return [_c('form-averbation',{attrs:{"register-options":_vm.registerOptions,"endorsement":_vm.endorsement,"has-external-contract-specifications":_vm.hasExternalContractSpecifications}}),_c('contract-specifications',{attrs:{"is-percentage":_vm.endorsement.division_method === 'percentage',"contract-specifications":_vm.endorsement.contract_specifications,"fields":_vm.contractSpecificationFields},on:{"import":_vm.handleImportedCsv,"reset":function($event){_vm.endorsement.contract_specifications = []}}}),_c('b-row',{staticClass:"justify-content-center"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid || !_vm.endorsement.contract_specifications.length},on:{"click":function($event){return _vm.validateAverbation()}}},[_vm._v(" Salvar Averbação ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","disabled":invalid || !_vm.endorsement.contract_specifications.length},on:{"click":function($event){return _vm.validateAverbation(true)}}},[_vm._v(" Salvar Averbação como Rascunho ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }